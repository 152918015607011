import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import './bootstrap.min.css'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

const sessionId = Math.random().toString().substr(2);
export default sessionId;

const blogs = []
const gmr = []
const bpiq = []
const intelliquest = []
const quantdata = []

localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));
localStorage.setItem("gmr"+sessionId, JSON.stringify(gmr));
localStorage.setItem("bpiq"+sessionId, JSON.stringify(bpiq));
localStorage.setItem("intelliquest"+sessionId, JSON.stringify(intelliquest));
localStorage.setItem("quantdata"+sessionId, JSON.stringify(quantdata));
