import React from 'react'
import QuantdataList from '../QuantDataList';
import sessionId from '..';

function QuantData() {
    const quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));
    var projectTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
    
    if(projectTitle == null){
      projectTitle = "Untitled Project";
    }

  return (
    <div className="home">
        <h1  style={{color: "#5cb215"}}>QuantData</h1>
      <br/>
      <QuantdataList quantdata={quantdata} title={projectTitle}/>
    </div>
  )
}

export default QuantData
