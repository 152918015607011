import React from 'react'
import IntelliquestList from '../IntelliquestList';
import sessionId from '..';

function IntelliQuest() {
    const intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));
    var projectTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
    
    if(projectTitle == null){
      projectTitle = "Untitled Project";
    }

  return (
    <div className="home">
        <h1  style={{color: "#5cb215"}}>IntelliQuest</h1>
      <br/>
      <IntelliquestList intelliquest={intelliquest} title={projectTitle}/>
    </div>
  )
}

export default IntelliQuest
