import Navbar from './Navbar';
import Home from './Home';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Create from './Create';
import CreateTopic from './CreateTopic';
import Project from './Project';
import BlogDetails from './BlogDetails';
import NotFound from './NotFound';
import EditBlog from './EditBlog';

import Header from './Components/Header'
import Footer from './Components/Footer'

import QuickStartScreen from './Screens/QuickStartScreen';
import FaqScreen from './Screens/FaqScreen';
import ProductsScreen from './Screens/ProductsScreen';
import ProductScreen from './Screens/ProductScreen';
import BlogScreen from './Screens/BlogScreen';
import ContactScreen from './Screens/ContactScreen';
import CartScreen from './Screens/CartScreen';
import ShippingScreen from './Screens/ShippingScreen';
import PaymentScreen from './Screens/PaymentScreen';
import PlaceOrderScreen from './Screens/PlaceOrderScreen';

import LoginScreen from './Screens/LoginScreen';
import ProfileScreen from './Screens/ProfileScreen';
import RegisterScreen from './Screens/RegisterScreen';
import OrderScreen from './Screens/OrderScreen';
import Academia from './Screens/Academia';

import AboutUs from './Screens/AboutUs';
import TermsAndConditions from './Screens/TermsAndConditions';
import PrivacyPolicy from './Screens/PrivacyPolicy';

import SmartMarketInvestigator from './Screens/SmartMarketInvestigator';
import GmrItemDetails from './GmrItemDetails';

import CreateBpiq from './CreateBpiq';
import BizPlanIQ from './Screens/BizPlanIQ';
import BpiqItemDetails from './BpiqItemDetails'; 

import CreateIntelliquest from './CreateIntelliquest';
import IntelliQuest from './Screens/IntelliQuest';
import IntelliquestItemDetails from './IntelliquestItemDetails';

import CreateQuantdata from './CreateQuantdata';
import QuantData from './Screens/QuantData';
import QuantDataItemDetails from './QuantDataItemDetails';

import { useState } from 'react';
import { SolverContext } from './Context';

function App() {

  const [solver, setSolver] = useState("")

  return (
    <SolverContext.Provider value={{solver, setSolver}}>
      <Router>
        <div className="App">
          <Header />
          <Navbar />
          <div className="content">
            <Routes>
              <Route path="/project"
                element = {<Project />} exact
              />
              <Route path="/createTopic"
                element = {<CreateTopic />} exact
                />
              <Route path="/createBpiq"
                element = {<CreateBpiq />} exact
                />
              <Route path="/createIntelliquest"
                element = {<CreateIntelliquest />} exact
                />
              <Route path="/createQuantdata"
                element = {<CreateQuantdata />} exact
                />
              <Route path="/"
                element = {<Home />} exact
                />
              <Route path="/create"
                element = {<Create />} exact
                />
              <Route path="/login"
                element = {<LoginScreen />} exact
                />
              <Route path="/register"
                element = {<RegisterScreen />} exact
                />
              <Route path="/profile"
                element = {<ProfileScreen />} exact
                />
              <Route path="/blogs/:id"
                element = {<BlogDetails />} exact
                />
              <Route path="/gmr/:id"
                element = {<GmrItemDetails />} exact
                />
              <Route path="/quantdata/:id"
                element = {<QuantDataItemDetails />} exact
                />
              <Route path="/bpiq/:id"
                element = {<BpiqItemDetails />} exact
                />
              <Route path="/intelliquest/:id"
                element = {<IntelliquestItemDetails />} exact
                />
              <Route path="/editBlog/:id"
                element = {<EditBlog />} exact
                />
              <Route path="/quickstart"
                element = {<QuickStartScreen />} exact
                />
              <Route path="/smi"
                element = {<SmartMarketInvestigator />} exact
              />
              <Route path="/bpiq"
                element = {<BizPlanIQ />} exact
              />
              <Route path="/quantdata"
                element = {<QuantData />} exact
              />
              <Route path="/intelliquest"
                element = {<IntelliQuest />} exact
              />
              <Route path="/faq"
                element = {<FaqScreen />} exact
                />
              <Route path="/academia"
                element = {<Academia />} exact
              />
              <Route path="/aboutUs"
                element = {<AboutUs />} exact
              />
              <Route path="/termsAndConditions"
                element = {<TermsAndConditions />} exact
              />
              <Route path="/privacyPolicy"
                element = {<PrivacyPolicy />} exact
              />
              <Route path="/products"
                element = {<ProductsScreen />} exact
                />
              <Route path="/products/:id"
                element = {<ProductScreen />} exact
                />
              <Route path="/cart/:id?"
                element = {<CartScreen />} exact
                />
              <Route path="/shipping"
                element = {<ShippingScreen />} exact
                />
              <Route path="/payment"
                element = {<PaymentScreen />} exact
                />
              <Route path="/placeorder"
                element = {<PlaceOrderScreen />} exact
                />
              <Route path="/order/:id"
                element = {<OrderScreen />} exact
                />
              <Route path="/blog"
                element = {<BlogScreen />} exact
                />
              <Route path="/contact"
                element = {<ContactScreen />} exact
                />
              <Route path="*"
                element = {<NotFound />}
                />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </SolverContext.Provider>
  );
}

export default App;

