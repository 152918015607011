import sessionId from './index';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoremIpsum } from "lorem-ipsum";

import Message from './Components/Message';
import Loader from './Components/Loader';

import { useDispatch, useSelector } from 'react-redux'
import { addResource } from './actions/SolverActions'
import { SOLVER_ADD_RESOURCE_RESET } from './constants/SolverConstants';

import ProgressBarLoader from './Components/ProgressBarLoader';

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const Create = () => {

  const totalDuration = 60.

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const history = useNavigate();

    const dispatch = useDispatch()

    const solverAddResource = useSelector(state => state.solverAddResource)
    const {error, loading, success} = solverAddResource

    const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));

    useEffect(() => {
        if(success){
          dispatch({type:SOLVER_ADD_RESOURCE_RESET});
          history('/');
        }
    }, [success])

    const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(addResource(url, blogs.length))
    }

    /* const handleSubmit = (e) => {
      e.preventDefault();

      const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
      const config = {
        'headers':{
            'Content-type':'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
      }  
      const {data} = axios.post('addResourceProd/', 
          {'url':url, 'outlineLength':blogs.length},
          config
      ).then((response) => {
        const newResource = response["data"]["currentResource"]
        blogs.push(newResource)
        localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));
      
        history('/');

      }, (error) => {
        console.log(error);
      }); 

    } */

    return (

      <div className="create">

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <ProgressBarLoader totalDuration={totalDuration} />}

        <h2>Add a New Resource</h2>
        <form onSubmit={handleSubmit}>
          <label>Resource Title:</label>
          <input 
            type="text" 
            //required 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Resource URL:</label>
          <input 
            type="text" 
            required 
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <button>Add Resource</button>
        </form>
      </div>
    );
  }
   
  export default Create; 