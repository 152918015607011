import React from 'react'

function AboutUs() {
  return (
    <div class="container_AboutUs">
        <div class="header_AboutUs">
            <h1>Welcome to Dataprosai-Platform.com (DPP)</h1>
            <p>Your gateway to revolutionary market intelligence and business growth tools.</p>
        </div>
        <div class="content_AboutUs">
            <p class="mission_AboutUs">Our Story</p>
            <p>DPP was born from the passion and expertise of AI and market intelligence specialists who once toiled within the confines of traditional tech companies. Frustrated by exorbitant market research costs and the lack of user-friendly options, we set out on a mission. Our goal: to revolutionize the market research landscape using the power of <span class="highlight_AboutUs">Artificial Intelligence</span>.</p>

            <p class="mission_AboutUs">Our Mission</p>
            <p>Our mission at DPP is clear: to empower you with easy-to-use, high-quality smart tools that slash the costs of conventional market research by orders of magnitude. We're not just talking about saving money; we're talking about saving time. Traditionally, market research took an average of three painstaking months to complete and cost anywhere from $5,000 to a staggering $50,000. With DPP, those hurdles are relics of the past.</p>

            <p class="mission_AboutUs">How We Help You</p>
            <p>With DPP, we put the power back into your hands. Imagine conducting comprehensive market research in a matter of minutes, starting for <span class="highlight_AboutUs">free</span>. Our platform merges the brilliance of AI and advanced tools to provide you with smart market intelligence. For tech entrepreneurs, this means uncovering untapped opportunities; for digital marketers, it means refining your strategies with unparalleled insights, and for product managers, it means making data-driven decisions that resonate with your audience.</p>

            <p class="mission_AboutUs">Why Choose DPP</p>
            <p>Choosing DPP means embracing innovation, efficiency, and affordability. We're not just a platform; we're your trusted partner in navigating the intricate world of market research. With us, you're not just a customer; you're an integral part of a movement that's transforming the industry.</p>

            <p>Join us at <span class="highlight">Dataprosai-Platform.com</span>, where market intelligence meets simplicity, and where your success story begins. Experience the future of market research – today.</p>
        </div>
    </div>
  )
}

export default AboutUs
