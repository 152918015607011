import React from 'react'

function ContactScreen() {
  return (
    <div>
      <h1>Contact</h1>
      <br />
      Contact us at <a href="mailto:contact@datapros.ai">contact@datapros.ai</a>.
    </div>
  )
}

export default ContactScreen
