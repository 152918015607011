import axios from 'axios'
import sessionId from '../index';

import {
    SOLVER_ADD_RESOURCE_REQUEST,
    SOLVER_ADD_RESOURCE_SUCCESS,
    SOLVER_ADD_RESOURCE_FAIL,

    SOLVER_ADD_TOPIC_REQUEST,
    SOLVER_ADD_TOPIC_SUCCESS,
    SOLVER_ADD_TOPIC_FAIL,

    SOLVER_ADD_GMR_REQUEST,
    SOLVER_ADD_GMR_SUCCESS,
    SOLVER_ADD_GMR_FAIL,

    SOLVER_ADD_BPIQ_REQUEST,
    SOLVER_ADD_BPIQ_SUCCESS,
    SOLVER_ADD_BPIQ_FAIL,
    SOLVER_ADD_BPIQ_RESET,

    SOLVER_ADD_INTELLIQUEST_REQUEST,
    SOLVER_ADD_INTELLIQUEST_SUCCESS,
    SOLVER_ADD_INTELLIQUEST_FAIL,
    SOLVER_ADD_INTELLIQUEST_RESET,

    SOLVER_ADD_QUANTDATA_REQUEST,
    SOLVER_ADD_QUANTDATA_SUCCESS,
    SOLVER_ADD_QUANTDATA_FAIL,
    SOLVER_ADD_QUANTDATA_RESET,
} from '../constants/SolverConstants'

export const addResource = (url, blogsLength) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_RESOURCE_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addResourceProd/`,
            {'url':url, 'outlineLength':blogsLength},
            config
        )

        const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
        const newResource = data["currentResource"]
        blogs.push(newResource)
        localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));

        dispatch({
            type: SOLVER_ADD_RESOURCE_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_RESOURCE_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const addTopic = (title, nbResourcesPerTopic, blogsLength) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_TOPIC_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addTopicProd/`,
            {'topicName':title, 'searchDepth': nbResourcesPerTopic, 'outlineLength':blogsLength},
            config
        )

        const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
        const addBlogs = data["currentTopic"]

        for (const currentBlog in addBlogs){
            blogs.push(addBlogs[currentBlog])
          }

        localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));

        dispatch({
            type: SOLVER_ADD_TOPIC_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_TOPIC_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const addGlobalMarketResearch = (title) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_GMR_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addGlobalMarketResearchProd/`,
            {'marketTopic':title},
            config
        )

        const gmr = data["current_gmr_result_list"];
        
        localStorage.setItem("gmr"+sessionId, JSON.stringify(gmr));
        localStorage.setItem("projectTitle"+sessionId, JSON.stringify(title));

        dispatch({
            type: SOLVER_ADD_GMR_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_GMR_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const addBizPlanIQ = (title, description) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_BPIQ_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addBizPlanIQProd/`,
            {'productName':title, "productDescription": description},
            config
        )

        const bpiq = data["current_bpiq_result_list"];
        
        localStorage.setItem("bpiq"+sessionId, JSON.stringify(bpiq));
        localStorage.setItem("projectTitle"+sessionId, JSON.stringify(title));

        dispatch({
            type: SOLVER_ADD_BPIQ_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_BPIQ_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const addIntelliQuest = (title, description, audienceDescription) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_INTELLIQUEST_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addIntelliQuestProd/`,
            {'productName':title, "productDescription": description, "audienceDescription": audienceDescription},
            config
        )

        const intelliquest = data["current_intelliquest_result_list"];
        
        localStorage.setItem("intelliquest"+sessionId, JSON.stringify(intelliquest));
        localStorage.setItem("projectTitle"+sessionId, JSON.stringify(title));

        dispatch({
            type: SOLVER_ADD_INTELLIQUEST_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_INTELLIQUEST_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const addQuantData = (title) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SOLVER_ADD_QUANTDATA_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`addQuantDataProd/`,
            {'marketTopic':title},
            config
        )

        const quantdata = data["current_quantdata_result_list"];
        
        localStorage.setItem("quantdata"+sessionId, JSON.stringify(quantdata));
        localStorage.setItem("projectTitle"+sessionId, JSON.stringify(title));

        dispatch({
            type: SOLVER_ADD_QUANTDATA_SUCCESS,            
        })
    }
    catch(error){
        dispatch(
            {
                type:SOLVER_ADD_QUANTDATA_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}
