import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';

const BlogDetails = () => {
  const { id } = useParams();

  const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
  const currentBlog = blogs.filter(blog => parseInt(blog.id) === parseInt(id));

  const history = useNavigate();

  const handleEdit = (id, e) => {
    e.preventDefault()
    history(`/editBlog/${id}`);
  }

  const handleDelete = () => {
    const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
    const newBlogs = blogs.filter(blog => blog.id != id);
    localStorage.setItem("blogs"+sessionId, JSON.stringify(newBlogs)); 
  
    history('/');
  }


  return (
    <div className="blog-details">
        <article>
            <h2>Blog details - { currentBlog[0].title }</h2>
            <p>Resource: { currentBlog[0].url }</p>
            <div>{ currentBlog[0].summary }</div>
            <div>{ currentBlog[0].outline.toString() }</div>

            {
              currentBlog[0].outline_dict["outline_details"].map(outline_detail => (
                <div className="blog-preview"  key={Math.random().toString()}>
                    <p>{ outline_detail[0] }</p>
                    <p>{ outline_detail[1] }</p>
                </div>
              ))
            }
            
            <div>Comment: { currentBlog[0].comment }</div>

            <button onClick = {(e) => handleEdit(id, e)}>Edit</button>
            <br></br>
            <br></br>
            <button onClick={handleDelete}>delete</button>
        </article>
    </div>
  );
}
 
export default BlogDetails;