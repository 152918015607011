import React from 'react'
import { scaleLinear, select, json } from 'd3'
import { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3';

function D3LineChart({ width, height, currentData, currentUnits, currentName }) {
    var margin = { left:100, right:0, top:10, bottom:100 }
    height = height - margin.top - margin.bottom
    width = width - margin.left - margin.right
    var svg;

  // store the data in state
  const [data, setData] = useState(currentData)

  // store a Ref to the SVG that will allow d3 to access the DOM element
  const svgRef = useRef()

  // when button clicked, randomly update the data stored in state, triggering a re-render
  const handleClick = () => setData(data)

  // when data or dimensions change, update the circle attributes - color, size, and position
  useEffect(() => {

    svg = select(svgRef.current).append("svg")
    .attr("y", 0)
    .attr("width", width)
    .attr("height", height);

    var g = svg.append("g")
    .attr("transform", "translate(" + margin.left + 
        ", " + margin.top + ")");

    // Time parser for x-scale
    var parseTime = d3.timeParse("%Y");
    // For tooltip
    var bisectDate = d3.bisector(function(d) { return d.x; }).left;

    // Scales
    var x = d3.scaleLinear().range([0, width*.8]);
    var y = d3.scaleLinear().range([height*.8, 0]);

    // Axis generators
    var xAxisCall = d3.axisBottom().ticks(6)

    var yAxisCall = d3.axisLeft()
        .ticks(6)
        .tickFormat(function(d) { return d; });

    // Axis groups
    var xAxis = g.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height*0.8 + ")");
    var yAxis = g.append("g")
        .attr("class", "y axis")
    
    // X Label
    g.append("text")
    .attr("class", "x axis-label")
    .attr("x", width / 2)
    .attr("y", height - 25)
    .attr("font-size", "30px")
    .attr("text-anchor", "middle")
    .text(currentName);

    // Y-Axis label
    yAxis.append("text")
        .attr("class", "axis-title")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .attr("fill", "#5D6971")
        .text(currentUnits)
        .attr("font-size", "30px");

    // Line path generator
    var line = d3.line()
        .x(function(d) { return x(d.x); })
        .y(function(d) { return y(d.y); });

    // Data cleaning
    data.forEach(function(d) {
        d.x = +d.x;
        d.y = +d.y;
    });

    // Set scale domains
    x.domain(d3.extent(data, function(d) { return d.x; }));
    y.domain([d3.min(data, function(d) { return d.y; }) / 1.1, 
        d3.max(data, function(d) { return d.y; }) * 1.1]);

    // Generate axes once scales have been set
    xAxis.call(xAxisCall.scale(x)).attr("font-size", "20px");
    yAxis.call(yAxisCall.scale(y)).attr("font-size", "20px");

    // Add line to chart
    g.append("path")
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "grey")
        .attr("stroke-with", "3px")
        .attr("d", line(data));

    }, [data, height, width])

  // this just attaches circles to the DOM - it doesn't actually set their size, color, or position
  return (
    <div>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
        {data.map((d) => (
          <line fill='#FFF' />
        ))}
      </svg>
{/*       <div>
        <button onClick={handleClick}>Refresh Data</button>
      </div> */}
    </div>
  )
}

export default D3LineChart
