import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';

function GmrItemDetails() {

    const { id } = useParams();

    const gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));
    const gmr_item = gmr.filter(item => parseInt(item.id) === parseInt(id));

    const history = useNavigate();

    const handleBack = () => {
        history(-1);
    }

    const handleDelete = () => {
        const userAnswer = window.confirm("Do you confirm to delete this element ?")
        if(userAnswer == false) {
            return
        }
        
        const gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));
        const newGmr = gmr.filter(item => item.id != id);
        localStorage.setItem("gmr"+sessionId, JSON.stringify(newGmr)); 
    
        history('/');
    }

  return (
    <div className="blog-details">
        <article>
            <h2>{ gmr_item[0].name.replaceAll("_", " ") }</h2>
            <h3>In brief</h3>
            <div>{ gmr_item[0].summary }</div>
            <h3>Detailed</h3>
            <div>
                {gmr_item[0].name == 'Competitive_Landscape' ? (
                        Object.entries(gmr_item[0].content)
                        .map( ([key, value]) => (
                            <div key={key}>
                                <h4>{key}</h4>
                                <p>{ value.map((item_i, i_i) => (
                                    <p key={i_i}>{ item_i}</p>
                                    )) }
                                </p>
                            </div>
                        ) )
                    ) : Array.isArray(gmr_item[0].content) ? (gmr_item[0].content.map((item, i) => (
                        <p key={i}>{ item}</p>
                ))) : gmr_item[0].content}
            </div>

            <br></br>
            <br></br>
            <button onClick={handleBack}>Back to the Outline</button>

            <br></br>
            <br></br>
            <button onClick={handleDelete} style={{ 
            color: 'white', 
                backgroundColor: '#b81414',
                borderRadius: '8px',
            }}>Delete this element</button>

        </article>
    </div>
  )
}

export default GmrItemDetails
