import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Academia() {
  return (
    <div>
        <Card style={{ width: '23rem' }}>
            <Card.Img variant="top" src="static/images/lean market research pros edition.png" />
            <Card.Body>
                <Card.Title>Lean Market Research Pros Edition</Card.Title>
                <Card.Text>
                    Upon the completion of this professional training, you will have a complete and clear picture about Lean Market Research from all its different aspects.
                    This professional training will provide you with a highly powerful skill that will constitute your biggest differentiator among the digital business world.
                    This course is meant to be a world reference and best in class professional training regarding Lean Market Research for Digital Businesses.
                    It contains a subtile mixture between fundamental and applied aspects of Lean Market Research.
                    The first goal of this professional training is to make you on the top best 1% of digital business analysts and strategists worldwide !
                    Enjoy !
                </Card.Text>
                <a href="https://leanmarketresearchprosedition.voomly.com"  target="_blank"><Button variant="primary">Subscribe to course $38.9</Button></a>
            </Card.Body>
        </Card>
        <br />

        <Card style={{ width: '23rem' }}>
            <Card.Img variant="top" src="static/images/lean market research pros edition lite.png" />
            <Card.Body>
                <Card.Title>Lean Market Research Pros Edition Lite</Card.Title>
                <Card.Text>
                    Upon the completion of this professional training, you will have a complete and clear picture about Lean Market Research from all its different aspects.
                    This professional training will provide you with a highly powerful skill that will constitute your biggest differentiator among the digital business world.
                    This course is meant to be straight to the point in its Lite version.
                    It contains a subtile mixture between fundamental and applied aspects of Lean Market Research.
                    Enjoy !
                </Card.Text>
                <a href="https://leanmarketresearchproseditionlite.voomly.com"  target="_blank"><Button variant="primary">Subscribe to course $18.9</Button></a>
            </Card.Body>
        </Card>
    </div>
  )
}

export default Academia
