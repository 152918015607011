import React from 'react'
import GmrList from '../GmrList';
import sessionId from '..';

function SmartMarketInvestigator() {

    const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
    const gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));
    var projectTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
    
    if(projectTitle == null){
      projectTitle = "Untitled Project";
    }

  return (
    <div className="home">
        <h1  style={{color: "#5cb215"}}>SMARGATOR</h1>
      <br/>
      <GmrList gmr={gmr} title={projectTitle}/>
    </div>
  )
}

export default SmartMarketInvestigator
