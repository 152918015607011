import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Card, ListGroup, Image, Form } from 'react-bootstrap'
import Message from '../Components/Message'
import { addToCart, removeFromCart } from '../actions/CartActions'
import { useParams, useNavigate, useLocation } from "react-router-dom";

function CartScreen() {
    const { id } = useParams();
    const productId = id;
    const navigate = useNavigate();
    const location = useLocation();

    const qty = location.search ? Number(location.search.split('=')[1]) : 1

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const {cartItems} = cart

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    useEffect(() => {
        if(productId){
            dispatch(addToCart(productId, qty))
        }
    }, [dispatch, productId, qty])

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    const checkoutHandler = () => {
        userInfo ? navigate('/shipping') : navigate('/login')
    }

    return (
        <Row>
            <Col md={8}>
                <h1>Shopping Cart</h1>
                {cartItems.length === 0 ? (
                    <Message variant='info'>
                        Your cart is empty <Link to = '/products'>Go back</Link>
                    </Message>
                ) : (
                    <ListGroup variant='flush'>
                        {cartItems.map(item => (
                            <ListGroup.Item key={item.product}>
                                <Row>
                                    <Col md={2}>
                                        <Image src={item.image} alt={item.product} fluid rounded />
                                    </Col>
                                    <Col  md={3}>
                                        <Link to={`/product/${item.product}`}>{item.name}</Link>
                                    </Col>
                                    <Col  md={2}>
                                        ${item.price}
                                    </Col>
                                    <Col  md={3}>
                                        {/* <Form.Control
                                            as="select" value={item.qty}
                                            onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}>
                                                {
                                                [...Array(item.countInStock).keys()].map((x) => (
                                                <option key={x+1} value={x + 1}>
                                                    {x + 1}
                                                </option>
                                                ))
                                                }
                                        </Form.Control> */}
                                    </Col>
                                    
                                    <Col md={1}>
                                        <Button type='button' variant='primary' 
                                        onClick = {() => removeFromCartHandler(item.product)}>
                                              Remove Item 
                                        </Button>
                                    </Col>

                                </Row>
                            </ListGroup.Item>
                        ))
                        }
                    </ListGroup>
                )
                }
            </Col>

            <Col md={4}>
                <Card>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Subtotal ({ cartItems.reduce((acc, item) => acc + item.qty, 0) }) items</h2>
                            ${ cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2) }
                        </ListGroup.Item>
                    </ListGroup>

                    <ListGroup>
                        <Button type = 'button' className='btn-block'
                            disabled={cartItems.length === 0}
                            onClick = {checkoutHandler}                            
                            >
                            Proceed to checkout
                        </Button>
                    </ListGroup>
                </Card>
            </Col>

        </Row>
    )
}

export default CartScreen
