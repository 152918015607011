import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
      <hr />
        <Container className="text-center py-1">
          <Col><Link to="/aboutUs" style={{ textDecoration: 'none' }}>About us</Link></Col>
          <Col><Link to="/termsAndConditions" style={{ textDecoration: 'none' }}>Terms and Conditions</Link></Col>
          <Col><Link to="/privacyPolicy" style={{ textDecoration: 'none' }}>Privacy Policy</Link></Col>
          <Col><Link to="/faq" style={{ textDecoration: 'none' }}>FAQ</Link></Col>
          <Col><Link to="/blog" style={{ textDecoration: 'none' }}>Blog</Link></Col>
          <Col><Link to="/contact" style={{ textDecoration: 'none' }}>Contact</Link></Col>  
        </Container>
        <Container>
            <Row>
                <Col className="text-center py-3">Copyright &copy; datapros.ai</Col>
            </Row>
        </Container>
    </footer>
  )
}

export default Footer
