
import sessionId from './index';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Message from './Components/Message';
import Loader from './Components/Loader';

import { getUserDetails } from './actions/UserActions';

import { useDispatch, useSelector } from 'react-redux'
import { addQuantData } from './actions/SolverActions'
import { SOLVER_ADD_QUANTDATA_RESET } from './constants/SolverConstants';

import { useContext } from "react";
import { SolverContext } from "./Context";

import ProgressBarLoader from './Components/ProgressBarLoader';

function CreateQuantdata() {
    const totalDuration = 60.

    const {solver, setSolver} = useContext(SolverContext);

    const userLogin = useSelector(state => state.userLogin)

    const [title, setTitle] = useState('');
    const history = useNavigate();

    const dispatch = useDispatch()

    const solverAddQuantdata = useSelector(state => state.solverAddQuantdata)
    const {error, loading, success} = solverAddQuantdata

    const quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));

    useEffect(() => {
      setSolver("quantdata")
        if(success){
          dispatch({type:SOLVER_ADD_QUANTDATA_RESET});
          dispatch(getUserDetails('profile'));
          history('/quantdata');
        }
    }, [success])

    const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(addQuantData(title))
    }

    return (
      <div className="create">

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <ProgressBarLoader totalDuration={totalDuration} />}

        <h2>Add a New Topic for gathering Quantitative Data</h2>
        <form onSubmit={handleSubmit}>
          <label>Topic Title:</label>
          <input 
            type="text" 
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <button>Run QuantData (5 Credits)</button>
        </form>
      </div>
    );
}

export default CreateQuantdata
