import { Link } from 'react-router-dom';

function IntelliquestList({ intelliquest, title}) {
    return (
        <div className="blog-list">
          <h3>{ title }</h3>
          {intelliquest.sort((a, b) => a.id - b.id).map(intelliquest_item => (
            <div className="blog-preview" key={intelliquest_item.id} >
              <Link to={`/intelliquest/${intelliquest_item.id}`}>
                  <h2>{ intelliquest_item.name.replaceAll("_", " ") }</h2>
                  <p>{ intelliquest_item.summary }</p>
              </Link>
            </div>
          ))}
        </div>
      );
}

export default IntelliquestList
