import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CheckoutSteps({step1, step2, step3, step4}) {
  return (
    <Nav className='justify-content-center mb-4'>
        
        {step1 ? (
            <Nav.Item>
                <Nav.Link href="/login">Login</Nav.Link>
            </Nav.Item>
        ): (
            <Nav.Link disabled>Login</Nav.Link>
        )}

        {step2 ? (
            <Nav.Item>
                <Nav.Link href="/shipping">Shipping</Nav.Link>
            </Nav.Item>
        ): (
            <Nav.Link disabled>Address</Nav.Link>
        )}

        {step3 ? (
            <Nav.Item>
                <Nav.Link href="/payment">Payment</Nav.Link>
            </Nav.Item>
        ): (
            <Nav.Link disabled>Payment</Nav.Link>
        )}

        {step4 ? (
            <Nav.Item>
                <Nav.Link href="/placeorder">Place Order</Nav.Link>
            </Nav.Item>
        ): (
            <Nav.Link disabled>Place Order</Nav.Link>
        )}

    </Nav>
  )
}

export default CheckoutSteps
