import {
    SOLVER_ADD_RESOURCE_REQUEST,
    SOLVER_ADD_RESOURCE_SUCCESS,
    SOLVER_ADD_RESOURCE_FAIL,
    SOLVER_ADD_RESOURCE_RESET,

    SOLVER_ADD_TOPIC_REQUEST,
    SOLVER_ADD_TOPIC_SUCCESS,
    SOLVER_ADD_TOPIC_FAIL,
    SOLVER_ADD_TOPIC_RESET,

    SOLVER_ADD_GMR_REQUEST,
    SOLVER_ADD_GMR_SUCCESS,
    SOLVER_ADD_GMR_FAIL,
    SOLVER_ADD_GMR_RESET,

    SOLVER_ADD_BPIQ_REQUEST,
    SOLVER_ADD_BPIQ_SUCCESS,
    SOLVER_ADD_BPIQ_FAIL,
    SOLVER_ADD_BPIQ_RESET,

    SOLVER_ADD_INTELLIQUEST_REQUEST,
    SOLVER_ADD_INTELLIQUEST_SUCCESS,
    SOLVER_ADD_INTELLIQUEST_FAIL,
    SOLVER_ADD_INTELLIQUEST_RESET,

    SOLVER_ADD_QUANTDATA_REQUEST,
    SOLVER_ADD_QUANTDATA_SUCCESS,
    SOLVER_ADD_QUANTDATA_FAIL,
    SOLVER_ADD_QUANTDATA_RESET,
} from '../constants/SolverConstants'

export const solverAddResourceReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_RESOURCE_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_RESOURCE_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_RESOURCE_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_RESOURCE_RESET:
            return {}

        default:
            return state
        }
    }

export const solverAddTopicReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_TOPIC_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_TOPIC_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_TOPIC_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_TOPIC_RESET:
            return {}

        default:
            return state
        }
    }

export const solverAddGmrReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_GMR_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_GMR_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_GMR_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_GMR_RESET:
            return {}

        default:
            return state
        }
    }

export const solverAddBpiqReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_BPIQ_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_BPIQ_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_BPIQ_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_BPIQ_RESET:
            return {}

        default:
            return state
        }
    }

export const solverAddIntelliquestReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_INTELLIQUEST_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_INTELLIQUEST_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_INTELLIQUEST_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_INTELLIQUEST_RESET:
            return {}

        default:
            return state
        }
    }

export const solverAddQuantdataReducer = (state = {}, action) => {
    switch(action.type){
        case SOLVER_ADD_QUANTDATA_REQUEST:
            return {...state, loading: true}
    
        case SOLVER_ADD_QUANTDATA_SUCCESS:
            return {loading: false, success:true}
    
        case SOLVER_ADD_QUANTDATA_FAIL:
            return {loading: false, error: action.payload}

        case SOLVER_ADD_QUANTDATA_RESET:
            return {}

        default:
            return state
        }
    }
    