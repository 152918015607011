import React, {useState, useEffect} from 'react'
import { redirect } from 'react-router-dom'
import { Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../Components/FormContainer'
import { useNavigate, useLocation } from 'react-router-dom'
import { saveShippingAddress } from '../actions/CartActions'
import CheckoutSteps from '../Components/CheckoutSteps'

function ShippingScreen() {

    const cart = useSelector(state => state.cart)
    const {shippingAddress} = cart

    const [address, setAddress] = useState(shippingAddress.address)
    const [city, setCity] = useState(shippingAddress.city)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({'address':address, 'city':city, 'postalCode':postalCode, 'country':country}))
        navigate('/payment')
    }

    return (
      <FormContainer>
          <CheckoutSteps step1 step2 />

          <h1>Billing Address</h1>
          <Form onSubmit={submitHandler}>

              <Form.Group controlId='address'>
                  <Form.Label>Address</Form.Label>
                  <Form.Control required type='text' placeholder='Enter address' value={address ? address : ''} onChange={(e) => setAddress(e.target.value)}>
                  </Form.Control>
              </Form.Group>

              <Form.Group controlId='city'>
                  <Form.Label>City</Form.Label>
                  <Form.Control required type='text' placeholder='Enter city' value={city ? city : ''} onChange={(e) => setCity(e.target.value)}>
                  </Form.Control>
              </Form.Group>

              <Form.Group controlId='postalCode'>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control required type='text' placeholder='Enter postal code' value={postalCode ? postalCode : ''} onChange={(e) => setPostalCode(e.target.value)}>
                  </Form.Control>
              </Form.Group>

              <Form.Group controlId='country'>
                  <Form.Label>Country</Form.Label>
                  <Form.Control required type='text' placeholder='Enter country' value={country ? country : ''} onChange={(e) => setCountry(e.target.value)}>
                  </Form.Control>
              </Form.Group>

              <Button type='submit' variant='primary'>
                  Continue
              </Button>

          </Form>
      </FormContainer>
    )
}

export default ShippingScreen
