import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {productListReducer, productDetailsReducer} from './reducers/ProductReducers'
import { cartReducer } from './reducers/CartReducers'

import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer} from './reducers/UserReducers'

import { orderCreateReducer, orderDetailsReducer, orderPayReducer, orderListMyReducer } from './reducers/OrderReducers'

import { solverAddResourceReducer, solverAddTopicReducer, solverAddGmrReducer, solverAddBpiqReducer, solverAddIntelliquestReducer, solverAddQuantdataReducer } from './reducers/SolverReducers'

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile:userUpdateProfileReducer, 

    productList:productListReducer,
    productDetails: productDetailsReducer,
    cart: cartReducer,

    orderCreate:orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderListMy: orderListMyReducer,

    solverAddResource: solverAddResourceReducer,
    solverAddTopic: solverAddTopicReducer,
    solverAddGmr: solverAddGmrReducer,
    solverAddBpiq: solverAddBpiqReducer,
    solverAddIntelliquest: solverAddIntelliquestReducer,
    solverAddQuantdata: solverAddQuantdataReducer,

})

const userInfoFromStorage = localStorage.getItem('userInfo') ? 
    JSON.parse(localStorage.getItem('userInfo')) : null

const cartItemsFromStorage = localStorage.getItem('cartItems') ? 
    JSON.parse(localStorage.getItem('cartItems')) : []

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ? 
    JSON.parse(localStorage.getItem('shippingAddress')) : {}

const initialState = {
    userLogin: {userInfo: userInfoFromStorage},
    cart: {cartItems: cartItemsFromStorage, shippingAddress:shippingAddressFromStorage},
}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))

export default store
