import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';

function IntelliquestItemDetails() {
    const { id } = useParams();

    const intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));
    const intelliquest_item = intelliquest.filter(item => parseInt(item.id) === parseInt(id));

    console.log(intelliquest_item)
    
    const history = useNavigate();

    const handleBack = () => {
        history(-1);
    }

    const handleDelete = () => {
        const userAnswer = window.confirm("Do you confirm to delete this element ?")
        if(userAnswer == false) {
            return
        }
        
        const intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));
        const newIntelliquest = intelliquest.filter(item => item.id != id);
        localStorage.setItem("intelliquest"+sessionId, JSON.stringify(newIntelliquest)); 
    
        history('/');
    }

  return (
    <div className="blog-details">
        <article>
            <h2>{ intelliquest_item[0].name.replaceAll("_", " ") }</h2>
            <h3>In brief</h3>
            <div>{ intelliquest_item[0].summary }</div>
            <h3>Detailed</h3>
            <div>
                {
                    intelliquest_item[0].name == 'Survey_Questionnaire' ? (
                            Object.entries(intelliquest_item[0].content)
                            .map( ([key, value]) => (
                                <div key={key}>
                                    {/* <h4>{key}</h4> */}
                                    <hr />
                                    <p>
                                        { value.map((item_i, i_i) => (
                                        <p key={i_i}>{ item_i}</p>
                                        )) }
                                    </p>
                                </div>
                            ) )
                        ): (
                            Object.entries(intelliquest_item[0].content)
                            .map( ([key, value]) => (
                                <div key={key}>
                                    {/* <h4>{key}</h4> */}
                                    <hr />
                                    <p>
                                        { Array.isArray(value) ? value.map((item_i, i_i) => (
                                            <span key={i_i}>{ item_i} | </span>
                                            )) : value }
                                    </p>
                                </div>
                            ) )
                        )
                        
                        }
            </div>

            <br></br>
            <br></br>
            <button onClick={handleBack}>Back to the Outline</button>

            <br></br>
            <br></br>
            <button onClick={handleDelete} style={{ 
            color: 'white', 
                backgroundColor: '#b81414',
                borderRadius: '8px',
            }}>Delete this element</button>

        </article>
    </div>
  )
}

export default IntelliquestItemDetails
