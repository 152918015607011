import React from 'react'

function BlogScreen() {
  return (
    <div>
      <h1>Blog</h1>
      <br />
      For in depth blog posts about market intelligence and business development, please visit <a href="https://datapros.ai">DataPros.ai</a>
    </div>
  )
}

export default BlogScreen
