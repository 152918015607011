import { Link } from 'react-router-dom';

function BpiqList({ bpiq, title}) {
    return (
        <div className="blog-list">
          <h3>{ title }</h3>
          {bpiq.sort((a, b) => a.id - b.id).map(bpiq_item => (
            <div className="blog-preview" key={bpiq_item.id} >
              <Link to={`/bpiq/${bpiq_item.id}`}>
                  <h2>{ bpiq_item.name.replaceAll("_", " ") }</h2>
                  <p>{ bpiq_item.summary }</p>
              </Link>
            </div>
          ))}
        </div>
      );
}

export default BpiqList
