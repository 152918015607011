
import sessionId from './index';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Message from './Components/Message';
import Loader from './Components/Loader';

import { getUserDetails } from './actions/UserActions';

import { useDispatch, useSelector } from 'react-redux'
import { addBizPlanIQ } from './actions/SolverActions'
import { SOLVER_ADD_BPIQ_RESET } from './constants/SolverConstants';

import { useContext } from "react";
import { SolverContext } from "./Context";

import ProgressBarLoader from './Components/ProgressBarLoader';

function CreateBpiq() {

  const totalDuration = 120.

    const {solver, setSolver} = useContext(SolverContext);

    const userLogin = useSelector(state => state.userLogin)

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const history = useNavigate();

    const dispatch = useDispatch()

    const solverAddBpiq = useSelector(state => state.solverAddBpiq)
    const {error, loading, success} = solverAddBpiq

    const bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));

    useEffect(() => {
      setSolver("bpiq")
        if(success){
          dispatch({type:SOLVER_ADD_BPIQ_RESET});
          dispatch(getUserDetails('profile'));
          history('/bpiq');
        }
    }, [success])

    const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(addBizPlanIQ(title, description))
    }

    return (
      <div className="create">

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <ProgressBarLoader totalDuration={totalDuration} />}

        <h2>Add a New Business Plan Topic</h2>
        <form onSubmit={handleSubmit}>
          <label>Product Title:</label>
          <input 
            type="text" 
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Product Description:</label>
          <input 
            type="text" 
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <button>Run BizPlanIQ (10 Credits)</button>
        </form>
      </div>
    );
}

export default CreateBpiq
