import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useContext } from "react";
import { SolverContext } from "./Context";

import sessionId from './index';

const Project = () => {

    const {solver, setSolver} = useContext(SolverContext);

    let currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
    if (currentTitle == null){
      currentTitle = ''
    }

    const [projectTitle, setProjectTitle] = useState(currentTitle);
    const history = useNavigate();
    
    const handleSubmit = (e) => {
        e.preventDefault();      
        history('/');
        localStorage.setItem("projectTitle"+sessionId, JSON.stringify(projectTitle));
      }

    const clearProject = (e) => {
      e.preventDefault();

      const userAnswer = window.confirm("Do you confirm to delete the project ?")
      if(userAnswer == false) {
        return
      }

      localStorage.setItem("projectTitle"+sessionId, JSON.stringify("Untitled Project"));
      setProjectTitle("Untitled Project")
      
      const newOutline = [];
      localStorage.setItem("blogs"+sessionId, JSON.stringify(newOutline));

      const newGmr = [];
      localStorage.setItem("gmr"+sessionId, JSON.stringify(newGmr));

      const newBpiq = [];
      localStorage.setItem("bpiq"+sessionId, JSON.stringify(newBpiq));

      const newIntelliquest = [];
      localStorage.setItem("intelliquest"+sessionId, JSON.stringify(newIntelliquest));

      const newQuantdata = [];
      localStorage.setItem("quantdata"+sessionId, JSON.stringify(newQuantdata));
    
      history('/project');
    }

    const prepareTextForDelivery = (currentOutline) => {
      let toReturn = '';
    
      const currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
      toReturn += currentTitle + "\n"
    
      for (let idx=0; idx<currentOutline.length; idx++){
      
        toReturn += "\n\n"
    
        let currentBlog = currentOutline[idx];
        toReturn += "\tTitle: " +  currentBlog.title + "\n";
        toReturn += "\t\tResource: " +  currentBlog.url + "\n";
        toReturn += "\t\tSummary: " +  currentBlog.summary + "\n";
        toReturn += "\t\tOutline: " + currentBlog.outline.toString() + "\n";
    
        toReturn += "\t\tDetails:\n"
          currentBlog.outline_dict["outline_details"].map(outline_detail => {
            toReturn += "\t\t\t" + outline_detail[0] + " : ";
            toReturn += outline_detail[1] + "\n";
          })
    
        toReturn += "\t\tComment:" +  currentBlog.comment + "\n";
      }
    
      return toReturn;
    }

    const prepareTextForDelivery_gmr = (currentOutline) => {
      let toReturn = '';
    
      const currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
      toReturn += currentTitle + "\n"
    
      for (let idx=0; idx<currentOutline.length; idx++){
      
        toReturn += "\n\n"
    
        let currentBlog = currentOutline[idx];
        toReturn += "\tSection: " +  currentBlog.name + "\n";
        toReturn += "\t\tSummary: " +  currentBlog.summary + "\n";
        toReturn += "\t\tContent: " + currentBlog.content + "\n";
      }
    
      return toReturn;
    }

    const prepareTextForDelivery_bpiq = (currentOutline) => {
      let toReturn = '';
    
      const currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
      toReturn += currentTitle + "\n"
    
      for (let idx=0; idx<currentOutline.length; idx++){
      
        toReturn += "\n\n"
    
        let currentBlog = currentOutline[idx];
        toReturn += "\tSection: " +  currentBlog.name + "\n";
        toReturn += "\t\tSummary: " +  currentBlog.summary + "\n";
        toReturn += "\t\tContent: " + currentBlog.content + "\n";
      }
    
      return toReturn;
    }

    const prepareTextForDelivery_intelliquest = (currentOutline) => {
      let toReturn = '';
    
      const currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
      toReturn += currentTitle + "\n"
    
      for (let idx=0; idx<currentOutline.length; idx++){
      
        toReturn += "\n\n"
    
        let currentBlog = currentOutline[idx];
        toReturn += "\tSection: " +  currentBlog.name + "\n";
        toReturn += "\t\tSummary: " +  currentBlog.summary + "\n";
        toReturn += "\t\tContent: " + currentBlog.content + "\n";
      }    
    
      return toReturn;
    }

    const prepareTextForDelivery_quantdata = (currentOutline) => {
      let toReturn = '';
    
      const currentTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
      toReturn += currentTitle + "\n"
    
      for (let idx=0; idx<currentOutline.length; idx++){
      
        toReturn += "\n\n"
    
        let currentBlog = currentOutline[idx];
        toReturn += "\tDataset Name: " +  currentBlog.name + "\n";
        toReturn += "\t\tData Type: " +  currentBlog["dataset type"] + "\n";
        toReturn += "\t\tUnits: " + currentBlog.units + "\n";
        toReturn += "\t\tData: " + JSON.stringify(currentBlog.data) + "\n";
      }

      return toReturn;
    }

    const Print = () => {
      let preparedTextForDelivery = ""
      if (solver == 'gmr') {
        let gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_gmr(gmr);
      }
      else if(solver == 'bpiq') {
        let bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_bpiq(bpiq);
      }
      else if(solver == 'intelliquest') {
        let intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_intelliquest(intelliquest);
      }
      else if(solver == 'quantdata') {
        let quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_quantdata(quantdata);
      }
      
      const file = new Blob([preparedTextForDelivery], {type: 'text/plain'});
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "outline-" + Date.now() + ".txt";
      document.body.appendChild(element);
      element.click();
    }

    const copyOutlineToClipboard = () => {
      let preparedTextForDelivery = ""
      if (solver == 'gmr') {
        let gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_gmr(gmr);
      }
      else if(solver == 'bpiq') {
        let bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_bpiq(bpiq);
      }
      else if(solver == 'intelliquest') {
        let intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_intelliquest(intelliquest);
      }
      else if(solver == 'quantdata') {
        let quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));
        preparedTextForDelivery = prepareTextForDelivery_quantdata(quantdata);
      }
      navigator.clipboard.writeText(preparedTextForDelivery);
      alert("Project outline copied in the clipboard!")
    }

    return (
        <div className="create">
        <h2>Edit Project Settings</h2>
        <form onSubmit={handleSubmit}>
          <label>Project Title:</label>
          <input 
            type="text" 
            required 
            value={projectTitle}
            onChange={(e) => setProjectTitle(e.target.value)}
          />
          <button>Edit Project</button>
        </form>

        <div>
          <button onClick={Print} style={{ 
            color: 'white', 
            backgroundColor: '#5e0066',
            borderRadius: '8px',
            margin: '8px',
          }}>Print Project</button>
        </div>

        <div>
          <button onClick={copyOutlineToClipboard} style={{ 
            color: 'white', 
            backgroundColor: '#5e0066',
            borderRadius: '8px',
            margin: '8px',
          }}>Copy Project</button>
        </div>

        <div>
          <button onClick={clearProject} style={{ 
            color: 'white', 
            backgroundColor: '#b81414',
            borderRadius: '8px',
            margin: '8px',
          }}>Delete Project</button>
        </div>

      </div>

      
    );
}

export default Project;

export function FunctionTwo() {
    return <div>Function Two</div>;
  }
  
  export function FunctionThree() {
    return <div>Function Three</div>;
  }
  
  export function FunctionFour() {
    return <div>Function Four</div>;
  }