import React from 'react'

function FaqScreen() {
  return (
    <div>
      <h1>FAQ</h1>
      <br/>
      <ul>
        <li>Is DPP free?: Yes you can sign up for free now on DPP and have access instantly to free credits that will allow you to perform your first smart market research. The credits are even recharged automatically each month.</li>
        <li>How does DPP work?: DPP is an AI based solution dedicated for market research. The engine takes your input and uses Large Language Models (LLMs), which is an advanced field of AI, in order to perform market research about your topic using AI oriented human expertise about market research.</li>
        <li>Does DPP only performs market research? : DPP performs market research as main value proposition. Upcoming products focusing on business planning, copywriting and contrent generation will be released in the future.</li>
      </ul>
    </div>
  )
}

export default FaqScreen
