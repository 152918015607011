import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { logout } from '../actions/UserActions'

function Header() {

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header>
            <Navbar bg="dark" variant="dark" expand="none" collapseOnSelect>
                <Container>
                  <LinkContainer to='/'>
                    <Navbar.Brand>Data Pros AI Platform</Navbar.Brand>
                  </LinkContainer>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">              
                        {userInfo ? (
                          <NavDropdown title={userInfo.name} id='username'>
                              
                              <LinkContainer to="/profile">
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                              </LinkContainer>

                              <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>

                          </NavDropdown>
                          ):
                          <LinkContainer to="/login"><Nav.Link><i className="fas -fa-user"></i>Login</Nav.Link></LinkContainer>
                        }

                      <NavDropdown title="Solutions" id='solutions'>
                        <LinkContainer to="/createTopic">
                          <NavDropdown.Item>SMARGATOR</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/createBpiq">
                          <NavDropdown.Item>BizPlanIQ</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/createIntelliquest">
                          <NavDropdown.Item>IntelliQuest</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/createQuantdata">
                          <NavDropdown.Item>QuantData</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>                      
                      <LinkContainer to="/cart"><Nav.Link>Cart</Nav.Link></LinkContainer>
                      <LinkContainer to="/quickstart"><Nav.Link>Quickstart</Nav.Link></LinkContainer>
                      <LinkContainer to="/academia"><Nav.Link>Academia</Nav.Link></LinkContainer>
                      <LinkContainer to="/products"><Nav.Link>Plans</Nav.Link></LinkContainer>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
  )
}

export default Header
