import axios from 'axios'

import { 
    ORDER_CREATE_REQUEST, 
    ORDER_CREATE_SUCCESS, 
    ORDER_CREATE_FAIL, 

    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,

    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL, 
    ORDER_PAY_RESET,

    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL, 
    ORDER_LIST_MY_RESET,
 } from "../constants/OrderConstants";

 import { CART_CLEAR_ITEMS } from '../constants/CartConstants'

 export const createOrder = (order) => async (dispatch, getState) => {
    try{
        dispatch({
            type: ORDER_CREATE_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(`orders/add/`,
            order,
            config
        )

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload : data
        })

        dispatch({
            type: CART_CLEAR_ITEMS,
            payload : data
        })

        localStorage.removeItem('cartItems')

    }
    catch(error){
        dispatch(
            {
                type:ORDER_CREATE_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }

}


export const getOrderDetails = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        
        const {data} = await axios.get(`orders/${id}/`,
            config
        )

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload : data
        })

    }
    catch(error){
        dispatch(
            {
                type:ORDER_DETAILS_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}


export const payOrder = (id, paymentResult) => async (dispatch, getState) => {
    console.log('in pay order now !')
    try{
        dispatch({
            type: ORDER_PAY_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        
        const {data} = await axios.put(`orders/${id}/pay/`,
            paymentResult,
            config
        )

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload : data
        })

    }
    catch(error){
        dispatch(
            {
                type:ORDER_PAY_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}

export const listMyOrders = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: ORDER_LIST_MY_REQUEST
        })

        const {userLogin: {userInfo}, } = getState()

        const config = {
            'headers':{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        
        const {data} = await axios.get(`orders/myorders/`, 
            config
        )

        dispatch({
            type: ORDER_LIST_MY_SUCCESS,
            payload : data
        })

    }
    catch(error){
        dispatch(
            {
                type:ORDER_LIST_MY_FAIL,
                payload: error.response && error.response.data.detail 
                ? error.response.data.detail 
                : error.message,
            }
        )
    }
}