import React from 'react'

function PrivacyPolicy() {
  return (
    <div class="container_PrivacyPolicy">
        <div class="header_PrivacyPolicy">
            <h1>Privacy Policy</h1>
        </div>
        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">1. Information We Collect</h2>
            <p>We collect personal information you provide directly to us, such as your name, email address, and payment details when you register for an account, subscribe to our services, or communicate with us. We may also collect non-personal information, such as browser type, operating system, and IP address, to enhance your experience on our platform.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
                <li>Providing and maintaining our services.</li>
                <li>Personalizing your experience and delivering relevant content.</li>
                <li>Processing transactions and sending transaction notifications.</li>
                <li>Responding to your inquiries and providing customer support.</li>
                <li>Sending periodic emails and updates about our services.</li>
                <li>Conducting research and analysis to improve our services.</li>
            </ul>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">3. Sharing Your Information</h2>
            <p>We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our website and services, as long as they agree to keep this information confidential.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">4. Security</h2>
            <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">5. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar tracking technologies to enhance your user experience and analyze usage patterns on our website. You can manage your cookie preferences through your browser settings.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">6. Your Choices</h2>
            <p>You can access, update, or delete your personal information by logging into your account settings. You can also unsubscribe from marketing communications by following the instructions provided in the emails we send.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">7. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the updated policy on our website.</p>
        </div>

        <div class="section_PrivacyPolicy">
            <h2 class="highlight_PrivacyPolicy">8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@datapros.ai">contact@datapros.ai</a>.</p>
        </div>

        <p class="highlight_PrivacyPolicy">By using DPP, you consent to the terms of this Privacy Policy. Thank you for entrusting us with your information.</p>
    </div>
  )
}

export default PrivacyPolicy
