
import sessionId from './index';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getUserDetails } from './actions/UserActions';

import { LoremIpsum } from "lorem-ipsum";

import Message from './Components/Message';
import Loader from './Components/Loader';

import { useDispatch, useSelector } from 'react-redux'
import { addGlobalMarketResearch } from './actions/SolverActions'
import { SOLVER_ADD_GMR_RESET } from './constants/SolverConstants';

import { useContext } from "react";
import { SolverContext } from "./Context";

import ProgressBarLoader from './Components/ProgressBarLoader';

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const nbResourcesPerTopic = 3;

const CreateTopic = () => {

  const totalDuration = 60.

    const {solver, setSolver} = useContext(SolverContext);

    const userLogin = useSelector(state => state.userLogin)

    const [title, setTitle] = useState('');
    const history = useNavigate();

    const dispatch = useDispatch()

    const solverAddGmr = useSelector(state => state.solverAddGmr)
    const {error, loading, success} = solverAddGmr

    const gmr = JSON.parse(localStorage.getItem("gmr"+sessionId));

    useEffect(() => {
      setSolver("gmr")
        if(success){
          dispatch({type:SOLVER_ADD_GMR_RESET});
          dispatch(getUserDetails('profile'));
          history('/smi');
        }
    }, [success])

    const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(addGlobalMarketResearch(title))
    }

    /* const handleSubmit = (e) => {
      e.preventDefault();

      const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));

      const config = {
        'headers':{
            'Content-type':'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
      } 
      const {data} = axios.post('addTopicProd/', 
          {'topicName': title, 'searchDepth':nbResourcesPerTopic, 'outlineLength':blogs.length},
          config
      ).then((response) => {
        
        const addBlogs = response["data"]["currentTopic"]

        for (const currentBlog in addBlogs){
            blogs.push(addBlogs[currentBlog])
          }

        localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));
        history('/');

        }, (error) => {
          console.log(error);
        });
    } */

    return (
      <div className="create">

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <ProgressBarLoader totalDuration={totalDuration} />}

        <h2>Add a New Market Research Topic</h2>
        <form onSubmit={handleSubmit}>
          <label>Topic Title:</label>
          <input 
            type="text" 
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <button>Run SMARGATOR (5 Credits)</button>
        </form>
      </div>
    );
}

export default CreateTopic; 