
export const SOLVER_ADD_RESOURCE_REQUEST = 'SOLVER_ADD_RESOURCE_REQUEST'
export const SOLVER_ADD_RESOURCE_SUCCESS = 'SOLVER_ADD_RESOURCE_SUCCESS'
export const SOLVER_ADD_RESOURCE_FAIL = 'SOLVER_ADD_RESOURCE_FAIL'
export const SOLVER_ADD_RESOURCE_RESET = 'SOLVER_ADD_RESOURCE_RESET'

export const SOLVER_ADD_TOPIC_REQUEST = 'SOLVER_ADD_TOPIC_REQUEST'
export const SOLVER_ADD_TOPIC_SUCCESS = 'SOLVER_ADD_TOPIC_SUCCESS'
export const SOLVER_ADD_TOPIC_FAIL = 'SOLVER_ADD_TOPIC_FAIL'
export const SOLVER_ADD_TOPIC_RESET = 'SOLVER_ADD_TOPIC_RESET'

export const SOLVER_ADD_GMR_REQUEST = 'SOLVER_ADD_GMR_REQUEST'
export const SOLVER_ADD_GMR_SUCCESS = 'SOLVER_ADD_GMR_SUCCESS'
export const SOLVER_ADD_GMR_FAIL = 'SOLVER_ADD_GMR_FAIL'
export const SOLVER_ADD_GMR_RESET = 'SOLVER_ADD_GMR_RESET'

export const SOLVER_ADD_BPIQ_REQUEST = 'SOLVER_ADD_BPIQ_REQUEST'
export const SOLVER_ADD_BPIQ_SUCCESS = 'SOLVER_ADD_BPIQ_SUCCESS'
export const SOLVER_ADD_BPIQ_FAIL = 'SOLVER_ADD_BPIQ_FAIL'
export const SOLVER_ADD_BPIQ_RESET = 'SOLVER_ADD_BPIQ_RESET'

export const SOLVER_ADD_INTELLIQUEST_REQUEST = 'SOLVER_ADD_INTELLIQUEST_REQUEST'
export const SOLVER_ADD_INTELLIQUEST_SUCCESS = 'SOLVER_ADD_INTELLIQUEST_SUCCESS'
export const SOLVER_ADD_INTELLIQUEST_FAIL = 'SOLVER_ADD_INTELLIQUEST_FAIL'
export const SOLVER_ADD_INTELLIQUEST_RESET = 'SOLVER_ADD_INTELLIQUEST_RESET'

export const SOLVER_ADD_QUANTDATA_REQUEST = 'SOLVER_ADD_QUANTDATA_REQUEST'
export const SOLVER_ADD_QUANTDATA_SUCCESS = 'SOLVER_ADD_QUANTDATA_SUCCESS'
export const SOLVER_ADD_QUANTDATA_FAIL = 'SOLVER_ADD_QUANTDATA_FAIL'
export const SOLVER_ADD_QUANTDATA_RESET = 'SOLVER_ADD_QUANTDATA_RESET'
