import { Link } from "react-router-dom";
import sessionId from './index';
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'

import { useContext } from "react";
import { SolverContext } from "./Context";

const Navbar = () => {

    const {solver, setSolver} = useContext(SolverContext);

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user} = userDetails

    return (
      <header className = "navBarLinks">

        <div className="solverHeader">
          {solver == "gmr" ? (<img id="bpiqImg" src="static/images/smargator.png" />): 
            solver == "bpiq" ? (<img id="bpiqImg" src="static/images/bizPlanIQ.png" />): 
              solver == "intelliquest" ? (<img id="bpiqImg" src="static/images/intelliQuest.png" />): 
                solver == "quantdata" ? (<img id="quantdataImg" src="static/images/quantData.png" />): 
                  (<h3></h3>)}
        </div>
           
        <nav className="navbar">
        {userInfo ? (
          <div className="links"> 
            {
              solver == "gmr" ? (<Link className='topicButton navbarButton' to="/createTopic">Add Topic</Link>) : 
                solver == "bpiq" ? (<Link className='topicButton navbarButton' to="/createBpiq">Add Topic</Link>) : 
                  solver == "intelliquest" ? (<Link className='topicButton navbarButton' to="/createIntelliquest">Add Topic</Link>) : 
                    solver == "quantdata" ? (<Link className='topicButton navbarButton' to="/createQuantdata">Add Topic</Link>) : 
                      (<Link className='topicButton navbarButton' to="/createTopic">Add Topic</Link>)
            }

            {
              solver == "gmr" ? (<Link className='outlineButton navbarButton' to="/smi">Outline</Link>) : 
                solver == "bpiq" ? (<Link className='outlineButton navbarButton' to="/bpiq">Outline</Link>) : 
                  solver == "intelliquest" ? (<Link className='outlineButton navbarButton' to="/intelliquest">Outline</Link>) : 
                    solver == "quantdata" ? (<Link className='outlineButton navbarButton' to="/quantdata">Outline</Link>) : 
                      (<Link className='outlineButton navbarButton' to="/smi">Outline</Link>)
            }
            
            <Link className='editButton navbarButton' to="/project">Edit</Link>
            <Link className='creditsButton navbarButton' to="/profile">{user.credits} Credits</Link>
        
          </div>
          ):
          <h6>Please <Link to="/login">login</Link> or <Link to="/register">register</Link> in order to have access to the solver</h6>
          }
        </nav>
        
      
      </header>
    );
  }
   
  export default Navbar;