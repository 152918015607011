import { Link } from 'react-router-dom';

function GmrList({ gmr, title}) {
  return (
    <div className="blog-list">
      <h3>{ title }</h3>
      {gmr.sort((a, b) => a.id - b.id).map(gmr_item => (
        <div className="blog-preview" key={gmr_item.id} >
          <Link to={`/gmr/${gmr_item.id}`}>
              <h2>{ gmr_item.name.replaceAll("_", " ") }</h2>
              <p>{ gmr_item.summary }</p>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default GmrList
