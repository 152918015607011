//import { useState } from "react";
import { Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

const Home = () => {

  return (
    <div className="home">

      
      <hr/>
      <br/>
      <div className="solutionsList">
        <h2>Discover our solutions</h2>
        <br/>
        <ul>
          <li>
            <Link to="/createTopic">
              <img id="smargatorImg" src="static/images/smargator.png" />
            </Link>
          </li>
          <li>
            <Link to="/createBpiq">
              <img id="bpiqImg" src="static/images/bizPlanIQ.png" />
            </Link>
          </li>
          <li>
            <Link to="/createIntelliquest">
              <img id="intelliquestImg" src="static/images/intelliQuest.png" />
            </Link>
          </li>
          <li>
            <Link to="/createQuantdata">
              <img id="quantdataImg" src="static/images/quantData.png" />
            </Link>
          </li>
        </ul>
      </div>

      <br/>
      <hr/>
      <br/>

      <h3>Disruptive Market Intelligence tools at your fingertips !</h3>
      <br/>

      <Card style={{ width: '20rem' }}>
          <Link to="/createTopic">
            <Card.Img variant="top" src="static/images/smargator.png" />
          </Link>
          <Card.Body>
              <Card.Title>SMARGATOR: The Smart Market Investigator</Card.Title>
              <Card.Text>
                Smart Market Investigator (SMARGATOR) transforms market intelligence for tech entrepreneurs, digital marketers, and product managers. Leveraging cutting-edge AI, we deliver instant, comprehensive market insights, empowering timely, informed decisions. By drastically reducing costs, our user-friendly platform ensures accessibility and affordability, democratizing high-quality market research. Accelerate your business strategies with SMI's agile, data-driven approach, transforming your ideas into actionable plans. Stay ahead of the curve, innovate confidently, and maximize growth potential – all at your fingertips. Welcome to a future where market intelligence meets simplicity, propelling your success to unprecedented heights.
              </Card.Text>
              <Link to="/createTopic"><Button variant="primary">Use SMARGATOR Now !</Button></Link>
          </Card.Body>
      </Card>

      <br/>

      <Card style={{ width: '20rem' }}>
          <Link to="/createBpiq">
            <Card.Img variant="top" src="static/images/bizPlanIQ.png" />
          </Link>
          <Card.Body>
              <Card.Title>BizPlanIQ: The Intuitive and Quick Solution for Business Planning</Card.Title>
              <Card.Text>
                Introducing BizPlanIQ: Your Business Genius Unleashed! BizPlanIQ is not just another business planning tool; it's your secret weapon for success. Powered by advanced AI and embedded expertise, it transforms your business ideas into compelling plans within minutes. Just provide your business name and a brief description, and watch as BizPlanIQ crafts your high-quality business blueprint. Success starts here!
              </Card.Text>
              <Link to="/createBpiq"><Button variant="primary">Use BizPlanIQ Now !</Button></Link>
          </Card.Body>
      </Card>

      <br/>

      <Card style={{ width: '20rem' }}>
          <Link to="/createIntelliquest">
            <Card.Img variant="top" src="static/images/intelliQuest.png" />
          </Link>
          <Card.Body>
              <Card.Title>IntelliQuest: The Intelligent Questionnaire Maker for your high quality primary market intelligence data </Card.Title>
              <Card.Text>
                Welcome to IntelliQuest: Where Insights Take Flight! Elevate your research game with IntelliQuest, your go-to SaaS solution. Powered by cutting-edge AI and expert insight, we create tailored surveys, in-depth interviews, and focus groups. Simply share your business name and idea description, and let IntelliQuest craft precise, engaging research tools. Uncover insights effortlessly!
              </Card.Text>
              <Link to="/createIntelliquest"><Button variant="primary">Use IntelliQuest Now !</Button></Link>
          </Card.Body>
      </Card>

      <Card style={{ width: '20rem' }}>
          <Link to="/createQuantdata">
            <Card.Img variant="top" src="static/images/quantData.png" />
          </Link>
          <Card.Body>
              <Card.Title>QuantData: Building Your Structured Quantitative Data out of Unstructured Raw Data! </Card.Title>
              <Card.Text>
                QuantData specializes in transforming unstructured narratives into structured insights. Our advanced AI algorithms dissect raw information, uncovering meaningful patterns and trends. From chaos to clarity, we empower businesses to make data-driven decisions. Experience the art of deciphering the unstructured, redefining how you perceive data. Dive into QuantData’s transformative journey!
              </Card.Text>
              <Link to="/createQuantdata"><Button variant="primary">Use QuantData Now !</Button></Link>
          </Card.Body>
      </Card>

      <br/>

      <hr />

      <br/>

      <h2>Welcome to Dataprosai-Platform.com: Empowering Your Success</h2>

      <p>At Dataprosai-Platform.com (DPP), we understand the challenges that tech entrepreneurs, digital marketers, and product managers face in the ever-evolving digital landscape. That's why we've created a groundbreaking solution tailored specifically for you. With DPP, you're not just gaining access to a platform; you're unlocking a world of unprecedented opportunities and insights. Here's how we transform your journey to success:</p>
    
      <ul>
        <li>
          <h3>Accelerated Market Intelligence</h3>
          <p>
          We provide you with instant, in-depth market insights that used to take months to gather. With DPP, you can make well-informed decisions at the speed of your ideas, ensuring you're always one step ahead of the competition.
          </p>
        </li>

        <li>
          <h3>Cost-Efficient Solutions</h3>
          <p>
          Say goodbye to exorbitant market research expenses. We've shattered the barriers of entry by offering high-quality, AI-driven market intelligence tools that don't break the bank. Your path to growth is now affordable and accessible, no matter the size of your budget.
          </p>
        </li>

        <li>
          <h3>User-Friendly Experience</h3>
          <p>
          We've designed our platform with you in mind. DPP offers a seamless, user-friendly interface that empowers you to navigate complex data effortlessly. No technical expertise required – explore, analyze, and strategize with confidence.
          </p>
        </li>
      </ul>

      <p>
      With DPP, the power to transform your business is at your fingertips. Join us in reshaping the future of market intelligence. Your success story starts here. Let's innovate, strategize, and grow together. Welcome to a new era of possibilities!
      </p>
    
    

    </div>
  );
}
 
export default Home;
