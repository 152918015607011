import React, {useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Form, Button, Card, ListGroupItem } from 'react-bootstrap'
import Rating from '../Components/Rating'
import Message from '../Components/Message'
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'

import { useParams, useNavigate } from "react-router-dom";
import { listProductDetails } from '../actions/ProductActions'

function ProductScreen() {
    const [qty, setQty] = useState(1)

    const { id } = useParams();
    const navigate = useNavigate();
  
    const dispatch = useDispatch()
  
    const productDetails = useSelector(state => state.productDetails)
    const {error, loading, product} = productDetails
  
    useEffect(() => {
      dispatch(listProductDetails(id))
  }, [dispatch])
  
    const addToCartHandler = () => {
      navigate(`/cart/${id}?qty=${qty}`)
    }
  
    return (
      <div>
        <Link to="/products" className="btn btn-light my-3">Go Back</Link>
        
        {
          loading ?
            <Loader />
            : error
              ? <Message variant='danger'>{error}</Message>
              :(
                <Row>
                  <Col md={6}>
                    <Image src={product.image} alt={product.name} fluid/>
                  </Col>
  
                  <Col md={3}>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <h3>{product.name}</h3>
                      </ListGroup.Item>
{/*                       <ListGroup.Item>
                        <Rating value={product.rating} text={`${product.numReviews}`} reviews color={'#f8e825'}/>
                      </ListGroup.Item> */}
                      <ListGroup.Item>
                        Price: ${product.price}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Description: {product.description}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
  
                  <Col md={3}>
                    <Card>
                      <ListGroup variant='flush'>
                        <ListGroup.Item>
                          <Row>
                            <Col>Price:</Col>
                            <Col>
                              <strong>${product.price}</strong>
                            </Col>
                          </Row>
                        </ListGroup.Item>
{/*                         <ListGroup.Item>
                          <Row>
                            <Col>Status:</Col>
                            <Col>
                              {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                            </Col>
                          </Row>
                        </ListGroup.Item> */}
  
{/*                         {product.countInStock > 0 && (
                          <ListGroup.Item>
                            <Row>
                              <Col>Qty</Col>
                              <Col xs='auto' class='my-1'>
                                <Form.Control
                                 as="select" value={qty}
                                 onChange={(e) => setQty(e.target.value)}>
                                  {
                                  [...Array(product.countInStock).keys()].map((x) => (
                                    <option key={x+1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  ))
                                  }
                                </Form.Control>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )} */}
  
                        <ListGroup.Item>
                          <Button onClick={addToCartHandler} className='btn-block' disabled={product.countInStock ? false : true} type='button'>Add to Cart</Button>
                        </ListGroup.Item>
                      </ListGroup>
  
                    </Card>
                  </Col>
  
                </Row>
              )
        }
        
        
      </div>
    )
}

export default ProductScreen
