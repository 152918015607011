import React, { useEffect, useState  } from 'react';

function ProgressBarLoader({totalDuration}) {

    const timeStep = totalDuration*1000. / 100.

    let progressInterval = null;

  const [progress, setProgress] = useState(0);
 
  useEffect(() => {
    progressInterval = setInterval(() => {
      setProgress(prev => prev==100 ? 100 : prev + 1);
    }, timeStep);
  }, []);
 
  useEffect(() => {
    if (progress >= 100) {
      clearInterval(progressInterval);
    }
  }, [progress]);
 
  return (
    <div className="m-5">
      <div className="progress w-75" style={{ height: 40 }}>
        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }}>{progress}%</div>
      </div>
    </div>
  );
}

export default ProgressBarLoader
