import React from 'react'

function QuickStartScreen() {
  return (
    <div>
      <div>
        <h1>Quick Start</h1>
        <br />
        You can use now DPP (DataProsPlatform) to perform nearly real time market intelligence.
        Have access now to actionable insights at a fraction of the classic time and budgets costs.
        Sign up and Start  for FREE now !
      </div> 
      <div>
        DPP is so efficient thanks to the use of 3 cutting edge differentiators:
        <ul>
          <li>AI</li>
          <li>Embedded expertise about market research</li>
          <li>Cloud computing</li>
        </ul>
      </div>
      <div>
        <h2>Introducing SMARGATOR: the Smart Market Investigator</h2>
        <iframe width="420" height="315"
          src="https://www.youtube.com/embed/4KDsSPQb62M?si=hy4zIP_gid8e-VWy?autoplay=1&mute=1">
        </iframe>
      </div>
    </div>
  )
}

export default QuickStartScreen
