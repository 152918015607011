import React from 'react'
import { scaleLinear, select, json } from 'd3'
import * as d3 from 'd3';
import { useEffect, useState, useRef } from 'react'
  
function D3BarChart({ width, height, currentData, currentUnits, currentName }) {
    // store the data in state
  const [data, setData] = useState(currentData)
    
  // store a Ref to the SVG that will allow d3 to access the DOM element
  const svgRef = useRef()

  // when button clicked, randomly update the data stored in state, triggering a re-render
  const handleClick = () => setData(data)

  // when data or dimensions change, update the circle attributes - color, size, and position
  useEffect(() => {

    var margin = { left:100, right:10, top:10, bottom:150 };

    var width = 600 - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

    var g = select(svgRef.current)
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform", "translate(" + margin.left 
                + ", " + margin.top + ")");

    // X Label
    g.append("text")
        .attr("class", "x axis-label")
        .attr("x", width / 2)
        .attr("y", height + 140)
        .attr("font-size", "30px")
        .attr("text-anchor", "middle")
        .text(currentName);

    // Y Label
    g.append("text")
        .attr("class", "y axis-label")
        .attr("x", - (height / 2))
        .attr("y", -60)
        .attr("font-size", "30px")
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        .text(currentUnits);

    data.forEach(function(d) {
        d.value = +d.value;
    });

    var x = d3.scaleBand()
        .domain(data.map(function(d){ return d.name; }))
        .range([0, width])
        .paddingInner(0.3)
        .paddingOuter(0.3);

    var y = d3.scaleLinear()
        .domain([0, d3.max(data, function(d){
            return d.value;
        })])
        .range([height, 0]);

    var xAxisCall = d3.axisBottom(x);
    g.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0, " + height + ")")
        .call(xAxisCall)
        .selectAll("text")
            .attr("y", "10")
            .attr("x", "-5")
            .attr("text-anchor", "end")
            .attr("font-size", "20px")
            .attr("transform", "rotate(-40)");

    var yAxisCall = d3.axisLeft(y)
        .ticks(3)
        .tickFormat(function(d){
            return d;
        });

    g.append("g")
        .attr("class", "y-axis")
        .call(yAxisCall)
        .attr("font-size", "20px");

    var rects = g.selectAll("rect")
        .data(data)
        

    rects.enter()
        .append("rect")
            .transition()
            .duration(1000)
            .attr("y", function(d){ return y(d.value); })
            .attr("x", function(d){ return x(d.name); })
            .attr("width", x.bandwidth)
            .attr("height", function(d){ return height - y(d.value); })
            .attr("fill", "grey")
            ;


  }, [data, height, width])

  // this just attaches circles to the DOM - it doesn't actually set their size, color, or position
  return (
    <div>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
        {data.map((d) => (
          <rect fill='#FFF' />
        ))}
      </svg>
{/*       <div>
        <button onClick={handleClick}>Refresh Data</button>
      </div> */}
    </div>
  )
}

export default D3BarChart
