import { Link } from 'react-router-dom';

function QuantDataList({ quantdata, title}) {
    return (
        <div className="blog-list">
          <h3>{ title }</h3>
          {quantdata.sort((a, b) => a.id - b.id).map(quantdata_item => (
            <div className="blog-preview" key={quantdata_item.id} >
              <Link to={`/quantdata/${quantdata_item.id}`}>
                  <h2>{ quantdata_item.name.replaceAll("_", " ") }</h2>
                  <p>{ quantdata_item['dataset type'] }</p>
              </Link>
            </div>
          ))}
        </div>
      );
}

export default QuantDataList
