import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';
import D3BarChart from "./Components/D3BarChart";
import D3PieChart from "./Components/D3PieChart";
import D3LineChart from "./Components/D3LineChart";

function QuantDataItemDetails() {

    var width = 1000
    var height = 500

    const { id } = useParams();

    const quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));
    const quantdata_item = quantdata.filter(item => parseInt(item.id) === parseInt(id)); 

    const history = useNavigate();

    const handleBack = () => {
        history(-1);
    }

    const handleDelete = () => {
        const userAnswer = window.confirm("Do you confirm to delete this element ?")
        if(userAnswer == false) {
            return
        }

        const quantdata = JSON.parse(localStorage.getItem("quantdata"+sessionId));
        const newQuantdata = quantdata.filter(item => item.id != id);
        localStorage.setItem("quantdata"+sessionId, JSON.stringify(newQuantdata)); 
    
        history('/');
    }

  return (
    <div className="blog-details">
        <article>
            <h2>{ quantdata_item[0].name.replaceAll("_", " ") }</h2>
            <h3>Data Type</h3>
                {quantdata_item[0]["dataset type"]}
            <h3>Units</h3>
                {quantdata_item[0].units}

            <hr />

            <div>
                <h3>Figure</h3>
                { quantdata_item[0]["dataset type"].toLowerCase() == 'bar chart' ? (
                    <D3BarChart width={width} height={height} currentData={quantdata_item[0].data} currentUnits={quantdata_item[0].units} currentName={quantdata_item[0].name.replaceAll("_", " ")} />
                ) : quantdata_item[0]["dataset type"].toLowerCase() == 'pie chart' ? (
                    <D3PieChart width={width} height={height} currentData={quantdata_item[0].data} currentUnits={quantdata_item[0].units} currentName={quantdata_item[0].name.replaceAll("_", " ")} />
                ) : quantdata_item[0]["dataset type"].toLowerCase() == 'time series' ? ( 
                    <D3LineChart width={width} height={height} currentData={quantdata_item[0].data} currentUnits={quantdata_item[0].units} currentName={quantdata_item[0].name.replaceAll("_", " ")} /> )
                : (
                    <D3LineChart width={width} height={height} currentData={quantdata_item[0].data} currentUnits={quantdata_item[0].units} currentName={quantdata_item[0].name.replaceAll("_", " ")} />
                    )
                    } 
            </div>

            <hr />

            <h3>Data</h3>
            <div>
                {
                    JSON.stringify(quantdata_item[0].data)
                }
            </div>

            <br></br>
            <br></br>
            <button onClick={handleBack}>Back to the Outline</button>

            <br></br>
            <br></br>
            <button onClick={handleDelete} style={{ 
            color: 'white', 
                backgroundColor: '#b81414',
                borderRadius: '8px',
            }}>Delete this element</button>

        </article>
    </div>
  )
}

export default QuantDataItemDetails
