import React, {useState, useEffect} from 'react'
import { redirect } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Message from '../Components/Message'
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import FormContainer from '../Components/FormContainer'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { getOrderDetails, payOrder } from '../actions/OrderActions'
import { ORDER_PAY_RESET } from '../constants/OrderConstants'

function OrderScreen(match) {

    const location = useLocation() 

    const orderId = location.pathname.split('/order/')[1].replace('/', '')
    const dispatch = useDispatch()

    const [sdkReady, setSdkReady] = useState(false)
    
    const orderDetails = useSelector(state => state.orderDetails)
    const {order, error, loading} = orderDetails

    const orderPay = useSelector(state => state.orderPay)
    const {loading: loadingPay, success: successPay} = orderPay

    const navigate = useNavigate()
    if (!loading && !error){
        order.itemsPrice = order.orderItems.reduce((acc, item) => acc+item.price * item.qty, 0).toFixed(2) 
    }

    //AQfkWM7hFfb6robbl6NPvrcur4ov3FcX5HqkGA1JB_DiuduUSz0SMxQyMqy58DuxhY2SxYqBHZmKIbhQ

    const addPaypalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.source = "https://www.paypal.com/sdk/js?client-id=AQfkWM7hFfb6robbl6NPvrcur4ov3FcX5HqkGA1JB_DiuduUSz0SMxQyMqy58DuxhY2SxYqBHZmKIbhQ"
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (location.search.split('dppTransactionComplete').length > 1) {
            dispatch(payOrder(orderId, "success"))
            navigate(location.pathname.slice(0, -1))        
        }
    
        if (location.search.split('canceled').length > 1) {
            navigate(location.pathname.slice(0, -1))
        }

        if(!order || successPay || order._id !== Number(orderId)){
            dispatch({type:ORDER_PAY_RESET})
            dispatch(getOrderDetails(orderId))
        }
        /* else if(!order.isPaid){
            if(window.paypal){
                addPaypalScript()
            }
            else{
                setSdkReady(true)
            }             
        } */
        
    }, [dispatch, order, orderId, successPay])

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult))
    }

    return (
        loading ? (
            <Loader />
        ) : error ? (
            <Message variant='danger'>{error}</Message>
        ) : (
            <div>
                <h1>Order: {order._id}</h1>
                <Row>
                    <Col md={8}>
                        <ListGroup type='flush'>
                            <ListGroup.Item>
                                <h2>Coordinates</h2>

                                <p><strong>Name: </strong>{order.user.name}</p>
                                <p><strong>Email: </strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>

                                <p>
                                    <strong>Address: </strong>
                                    {order.shippingAddress.address}, {order.shippingAddress.city},
                                    {'   '}
                                    {order.shippingAddress.postalCode}, {order.shippingAddress.country}
                                </p>

                                {order.isDelivered ? (
                                    <Message variant='success'>Delivered on {order.deliveredAt}</Message>
                                    ) : 
                                    (<Message variant='warning'>Not delivered</Message>)
                                }

                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h2>Payment Method</h2>
                                <p>
                                    <strong>Mehtod: </strong>
                                    Credit Card
                                    {/* {order.paymentMethod} */}
                                    
                                </p>

                                {order.isPaid ? (
                                    <Message variant='success'>Paid on {order.paidAt}</Message>
                                    ) : 
                                    (<Message variant='warning'>Not paid</Message>)
                                }
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h2>Order Items</h2>
                                {order.orderItems.length === 0 ? <Message variant='info'>Order is empty</Message> : 
                                    (<ListGroup variant = 'flush'>
                                        {order.orderItems.map((item, index) => (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col md={1}>
                                                        <Image src={item.image} alt={item.name} fluid rounded />
                                                    </Col>

                                                    <Col>
                                                        <Link to={`/products/${item.product}`}>{item.name}</Link>
                                                    </Col>

                                                    <Col md={4}>
                                                        {item.qty} X ${item.price} = ${(item.qty *  item.price).toFixed(2)}
                                                    </Col>

                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>) 
                                }
                            </ListGroup.Item>

                        </ListGroup>
                    </Col>

                    <Col md={4}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2>Order Summary</h2>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>Item:</Col>
                                        <Col>${order.itemsPrice}</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>Address:</Col>
                                        <Col>${order.shippingPrice}</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>Tax:</Col>
                                        <Col>${order.taxPrice}</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col>Total:</Col>
                                        <Col>${order.totalPrice}</Col>
                                    </Row>
                                </ListGroup.Item>
                                
                                <ListGroup.Item>
                                    {error && <Message variant='danger'>{error}</Message>}
                                </ListGroup.Item>

                                {!order.isPaid && (
                                    <ListGroup.Item>

                                        <form action="orders/create-checkout-session" method="POST">
                                            <input type = "hidden" name = "totalAmount" value = {order.totalPrice} />
                                            <input type = "hidden" name = "currentUrl" value = {location.pathname} />
                                            <input type = "hidden" name = "orderId" value = {orderId} />
                                            <Button type='submit' variant='primary'>Checkout</Button>
                                        </form>
                                        {/* {loadingPay && <Loader />}

                                        {!sdkReady ? (
                                            <Loader />
                                        ) : (
                                            <PayPalButton 
                                                amount={order.totalPrice}
                                                onSuccess={successPaymentHandler}
                                            />
                                        )} */}
                                    </ListGroup.Item>
                                )}

                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    )
}

export default OrderScreen
