
import sessionId from './index';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Message from './Components/Message';
import Loader from './Components/Loader';

import { getUserDetails } from './actions/UserActions';

import { useDispatch, useSelector } from 'react-redux'
import { addIntelliQuest } from './actions/SolverActions'
import { SOLVER_ADD_INTELLIQUEST_RESET } from './constants/SolverConstants';

import { useContext } from "react";
import { SolverContext } from "./Context";

import ProgressBarLoader from './Components/ProgressBarLoader';

function CreateIntelliquest() {

  const totalDuration = 60.

    const {solver, setSolver} = useContext(SolverContext);

    const userLogin = useSelector(state => state.userLogin)

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [audienceDescription, setAudienceDescription] = useState('');
    const history = useNavigate();

    const dispatch = useDispatch()

    const solverAddIntelliquest = useSelector(state => state.solverAddIntelliquest)
    const {error, loading, success} = solverAddIntelliquest

    const intelliquest = JSON.parse(localStorage.getItem("intelliquest"+sessionId));

    useEffect(() => {
      setSolver("intelliquest")
        if(success){
          dispatch({type:SOLVER_ADD_INTELLIQUEST_RESET});
          dispatch(getUserDetails('profile'));
          history('/intelliquest');
        }
    }, [success])

    const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(addIntelliQuest(title, description, audienceDescription))
    }

    return (
      <div className="create">

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <ProgressBarLoader totalDuration={totalDuration} />}

        <h2>Add a New Intelligent Questionnaire Topic</h2>
        <form onSubmit={handleSubmit}>
          <label>Product Title:</label>
          <input 
            type="text" 
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Product Description:</label>
          <input 
            type="text" 
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <label>Audience Description (Optional):</label>
          <input 
            type="text" 
            value={audienceDescription}
            onChange={(e) => setAudienceDescription(e.target.value)}
          />
          <button>Run IntelliQuest (5 Credits)</button>
        </form>
      </div>
    );
}

export default CreateIntelliquest
