import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';

function BpiqItemDetails() {
    const { id } = useParams();

    const bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));
    const bpiq_item = bpiq.filter(item => parseInt(item.id) === parseInt(id));

    const history = useNavigate();

    const handleBack = () => {
        history(-1);
    }

    const handleDelete = () => {
        const userAnswer = window.confirm("Do you confirm to delete this element ?")
        if(userAnswer == false) {
            return
        }
        
        const bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));
        const newBpiq = bpiq.filter(item => item.id != id);
        localStorage.setItem("bpiq"+sessionId, JSON.stringify(newBpiq)); 
    
        history('/');
    }

  return (
    <div className="blog-details">
        <article>
            <h2>{ bpiq_item[0].name.replaceAll("_", " ") }</h2>
            <h3>In brief</h3>
            <div>{ bpiq_item[0].summary }</div>
            <h3>Detailed</h3>
            <div>
                {Object.entries(bpiq_item[0].content)
                        .map( ([key, value]) => (
                            <div key={key}>
                                {/* <h4>{key}</h4> */}
                                <hr />
                                <p>{ Array.isArray(value) ? value.map((item_i, i_i) => (
                                    <p key={i_i}>{ item_i}</p>
                                    )) : value }
                                </p>
                            </div>
                        ) )}
            </div>

            <br></br>
            <br></br>
            <button onClick={handleBack}>Back to the Outline</button>

            <br></br>
            <br></br>
            <button onClick={handleDelete} style={{ 
            color: 'white', 
                backgroundColor: '#b81414',
                borderRadius: '8px',
            }}>Delete this element</button>

        </article>
    </div>
  )
}

export default BpiqItemDetails
