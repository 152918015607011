import React from 'react'
import BpiqList from '../BpiqList';
import sessionId from '..';

function BizPlanIQ() {
    const bpiq = JSON.parse(localStorage.getItem("bpiq"+sessionId));
    var projectTitle = JSON.parse(localStorage.getItem("projectTitle"+sessionId));
    
    if(projectTitle == null){
      projectTitle = "Untitled Project";
    }

  return (
    <div className="home">
        <h1  style={{color: "#5cb215"}}>BizPlanIQ</h1>
      <br/>
      <BpiqList bpiq={bpiq} title={projectTitle}/>
    </div>
  )
}

export default BizPlanIQ
