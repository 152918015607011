import {React, useState} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import sessionId from './index';

function EditBlog() {
    const { id } = useParams();

    const blogs = JSON.parse(localStorage.getItem("blogs"+sessionId));
    const currentBlog = blogs.filter(blog => parseInt(blog.id) === parseInt(id));
    const history = useNavigate();

    const [resourceTitle, setResourceTitle] = useState(currentBlog[0].title);
    const [resourceComment, setResourceComment] = useState(currentBlog[0].comment);

    const handleSubmit = (e) => {
        e.preventDefault();
        currentBlog[0].title = resourceTitle
        currentBlog[0].comment = resourceComment

        localStorage.setItem("blogs"+sessionId, JSON.stringify(blogs));
        history(`/blogs/${id}`);
      }

    return (
        <div className="create">
        <h2>Edit Resource Settings</h2>
        <form onSubmit={handleSubmit}>
          <label>Resource Title:</label>
          <input 
            type="text" 
            required 
            value={resourceTitle}
            onChange={(e) => setResourceTitle(e.target.value)}
          />

            <textarea 
            rows="5"
            //required 
            value={resourceComment}
            onChange={(e) => setResourceComment(e.target.value)}
          />

          <button>Edit Resource</button>

        </form>

      </div>
    )
}

export default EditBlog
