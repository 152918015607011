import React from 'react'
import { scaleLinear, select, json } from 'd3'
import * as d3 from 'd3';
import { useEffect, useState, useRef } from 'react'

function D3PieChart({ width, height, currentData, currentUnits, currentName }) {
    // store the data in state
    const [data, setData] = useState(currentData)
    
  // store a Ref to the SVG that will allow d3 to access the DOM element
  const svgRef = useRef()

  // when button clicked, randomly update the data stored in state, triggering a re-render
  const handleClick = () => setData(data)
  
    // when data or dimensions change, update the circle attributes - color, size, and position
  useEffect(() => {

    var margin = { left:100, right:10, top:10, bottom:150 };

    var width = 600 - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

    var svg = d3.select(svgRef.current),

        radius = Math.min(width, height) / 2,
        g = svg.append("g").attr("transform", "translate(" + width + "," + (height+25) + ")");

        var color = d3.scaleOrdinal(['#4daf4a','#377eb8','#ff7f00','#984ea3','#e41a1c','#4daf4a','#377eb8','#ff7f00','#984ea3','#e41a1c']);

        // Generate the pie
        var pie = d3.pie().value(function(d) { 
            return d.value; 
        });
    
        // Generate the arcs
        var path = d3.arc()
                     .outerRadius(radius - 10)
                     .innerRadius(225);
    
        var label = d3.arc()
            .outerRadius(radius)
            .innerRadius(radius - 80);

        //Generate groups
        var arc = g.selectAll(".arc")
                    .data(pie(data))
                    .enter()
                    .append("g")
                    .attr("class", "arc")
    
        //Draw arc paths
        arc.append("path")
        .attr("d", path)
        .attr("fill", function(d) { return color(d.data.name); });
    
        arc.append("text")
               .attr("transform", function(d) { 
                        return "translate(" + [label.centroid(d)[0]*2., label.centroid(d)[1]*2.] + ")"; 
                })
               .text(function(d) { return d.data.name; })
               .attr("font-size", "30px");
        
        svg.append("g")
            .attr("transform", "translate(" + (width / 2 - 120) + "," + 30 + ")")
            .append("text")
            .transition()
            .duration(1000)
            .text(currentName)
            .attr("class", "title")
            .attr("font-size", "30px")
    }, [data, height, width])

    // this just attaches circles to the DOM - it doesn't actually set their size, color, or position
    return (
        <div>
        <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
            {data.map((d) => (
            <arc />
            ))}
        </svg>
{/*         <div>
            <button onClick={handleClick}>Refresh Data</button>
        </div> */}
        </div>
    )
}

export default D3PieChart
