import React from 'react'

function TermsAndConditions() {
  return (
    <div class="container_TermsAndConditions">
        <div class="header_TermsAndConditions">
            <h1>Terms and Conditions</h1>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">1. Acceptance of Terms</h2>
            <p>By accessing or using Dataprosai-Platform.com (referred to as "DPP," "we," "our," or "us"), you agree to comply with and be bound by these Terms and Conditions.</p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">2. User Account</h2>
            <p>To access certain features of DPP, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">3. User Conduct</h2>
            <p>You agree not to engage in any of the following prohibited activities: (a) violating laws and regulations; (b) infringing intellectual property rights; (c) harassing, abusing, or harming others; (d) engaging in spamming or phishing.</p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">4. Modifications to Service</h2>
            <p>We reserve the right to modify, suspend, or discontinue, temporarily or permanently, the services or any part of it, with or without notice. You agree that we shall not be liable to you or any third party for any modification, suspension, or discontinuance of the services.</p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">5. Governing Law</h2>
            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the French Jurisdiction, where the mother company of DPP is located, that is DataHiveConsulting, without regard to its conflict of law principles.</p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">6. Disclaimer of Responsibility for Decision-Making based on Insights</h2>
            <p>
            DPP, its owners, and editors provide the AI-driven insights and information on an as-is basis. While we strive to deliver accurate and valuable insights through the use of AI and online data, it is essential to note that the results are generated by algorithms and are not double-checked by human experts.
            Users are hereby informed that DPP does not take responsibility for any decisions, whether business or personal, made based on the insights provided by the platform. Users are encouraged to independently verify and validate the insights, seek professional advice, and exercise their judgment before implementing any decisions based on the information obtained through DPP.
            By using DPP, users acknowledge and agree that the platform and its editors are not liable for any consequences, losses, or damages arising from decisions made in reliance on the AI-generated insights.
            It is recommended to consult with qualified professionals and experts in relevant fields for a thorough evaluation of the insights and to mitigate potential risks associated with decision-making based on AI-generated data.
            </p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">7. Limitations of DPP's AI-Generated Insights</h2>
            <p>
            While DPP employs advanced AI technology to deliver valuable insights, users should be aware of the current known limitations inherent in the platform:
            <ul>
                <li><b><b>Data Accuracy:</b></b> DPP relies on publicly available online data, and the accuracy of insights is contingent on the accuracy of the underlying data sources. Discrepancies or inaccuracies in these sources may impact the reliability of the results.</li>
                <li><b><b>Algorithmic Limitations:</b></b> DPP utilizes algorithms to generate insights, and as such, the platform may not capture certain nuances or evolving trends that may be apparent to human experts.</li>
                <li><b><b>No Human Expert Review:</b></b> Insights provided by DPP are not double-checked by human experts before delivery. Users are encouraged to exercise their judgment and seek professional advice for critical decisions.</li>
                <li><b><b>Dynamic Business Landscape:</b></b> The business environment is dynamic, and circumstances may change rapidly. Insights generated by DPP are based on historical and existing data and may not account for real-time developments.</li>
            </ul>
            By using DPP, users acknowledge these limitations and understand that the platform is not a substitute for human expertise. It is recommended to complement AI-generated insights with human judgment and expert consultation for comprehensive decision-making.
            </p>
        </div>

        <div class="section_TermsAndConditions">
            <h2 class="highlight_TermsAndConditions">8. Contact Us</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:contact@datapros.ai">contact@datapros.ai</a>.</p>
        </div>

        <p class="highlight_TermsAndConditions">By using DPP, you agree to these Terms and Conditions. Thank you for using Dataprosai-Platform.com!</p>
    </div>
  )
}

export default TermsAndConditions
